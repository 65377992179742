.card-alerta {
    border: 1px solid #cacaca !important;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(128, 128, 128, 0.552);
}
.fadeOut {
    animation: fadeOut 1s;
}
@keyframes fadeOut {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: 200;
    }
}

.flotante-r {
    position: absolute;
    top: 0;
    right: 6px;
}
.flotante-l {
    position: absolute;
    margin-bottom: 2px;
    bottom: 0;
    left: 3px;
    background-color: #0014b0;
    color: white;
    border-radius: 30px;
    padding: 0px 8px;
}

/* waves_2 */
.ocean_11 {
    height: 100%; /* change the height of the waves here */
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
  }
  
  .wave_11 {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23FF1E00'/%3E%3C/svg%3E");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave_11 40s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
  }
  
  .wave_11:nth-of-type(2) {
    bottom: 0;
    animation: wave_11 20s linear reverse infinite;
    opacity: 0.5;
  }
  
  .wave_11:nth-of-type(3) {
    bottom: 0;
    animation: wave_11 23s -1s linear reverse infinite;
    opacity: 0;
  }
  
  @keyframes wave_11 {
    0% {transform: translateX(0);}
    25% {transform: translateX(-25%);}
    50% {transform: translateX(-50%);}
    75% {transform: translateX(-25%);}
    100% {transform: translateX(0);}
  }

  .alertafloat {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
  }
  .testing {
    color: #ffff00;
  }