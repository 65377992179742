.header {
  background-color: #d0d3d4;/*rgb(208, 4, 4);*/
  color:  white!important; /*rgb(255, 255, 255);#424949*/
}

.isNotShow {
  display: none;
}

.active {
  background-color: #fff !important;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
}
.leaflet-bar-part {
  display: none !important;
}

.geosearch {
  position: absolute !important;
  right: 0;
  width: 75%;
}

form {
  align-items: flex-end;
  align-content: flex-end;
}

.glass {
  width: 90%;
  border-radius: 5px !important;
}

.leaflet-top {
  width: 100%;
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 0px !important;
}

.reset {
  border-radius: 5px !important;
}
.color-icon-menu {
  color: #252929!important;/*rgb(102, 1, 1);*/
}

/* menu header CSS */
.menu-container {
  padding: 0px 0px;
  /* height: 100vh; */
  background-color: #d0d3d4 !important; /*rgb(208, 4, 4) !important;*/
}
/* .MuiPaper-root {
  background-color: rgb(208, 4, 4) !important;
} */
.menu-item {
  padding: 7px 0px 7px 10px;
  color: #424949 !important; /*rgb(255, 255, 255);*/
  cursor: pointer;
}
.menu-item:hover {
  color: white !important; /*rgb(255, 230, 0);*/
  background-color: #424949 !important;/*rgb(170, 0, 0);*/
}
.menu-item:hover>.color-icon-menu {
  color: white !important;/*rgb(234, 0, 0);*/
}
.selected-item {
  background-color: #424949 !important;/*rgb(184, 1, 1);*/
  color:  #ffffff!important;
}
.spacing {
  margin-left: 10px;
}
hr {
  height: 2px;
  background-color: rgb(38, 38, 38);
}
.menu-footer-version {
  color: #ffffff;
  text-align: center;
  /* position: absolute; */
  bottom: 0px;
  width: 100%;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
}
.menubtn {
  cursor: pointer;
  padding: 0px 8px;
  color: rgb(255, 255, 255);
  filter: drop-shadow(2px 2px 2px rgb(255, 255, 255, .5));
  /* box-shadow: 2px 2px 6px yellow; */
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background: white;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7.5px 14px !important;
}

/* btn uploadfile */
input[type="file"]#file {
  top: -25px;
  margin: auto;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: -1;
}
#secondfile {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}


/* waves_2 */
.ocean_2 {
  height: 100%; /* change the height of the waves here */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

.wave_2 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23424949'/%3E%3C/svg%3E");/*23FF1E00*/
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave_2 40s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}

.wave_2:nth-of-type(2) {
  bottom: 0;
  animation: wave_2 20s linear reverse infinite;
  opacity: 0.5;
}

.wave_2:nth-of-type(3) {
  bottom: 0;
  animation: wave_2 23s -1s linear reverse infinite;
  opacity: 0.3;
}

@keyframes wave_2 {
  0% {transform: translateX(0);}
  25% {transform: translateX(-25%);}
  50% {transform: translateX(-50%);}
  75% {transform: translateX(-25%);}
  100% {transform: translateX(0);}
}

.loadding {
  animation: loadingA 2s ease-in-out infinite;
}

@keyframes loadingA {
  0% {opacity: 1;}
  50% {opacity: 0; scale: 1.2;}
  100% {opacity: 1;}
}

.highcharts-credits {
  display: none;
}
.MuiDrawer-paper {
  background-color: #D0D3D4 !important;
}

/* modal waitinng */

.loader-01 {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  z-index: +999999;
}

.container-load-01 {
  align-self: center;
}

.inner-load-01 {
  width: auto;
  font-weight: bold;
  color: #656565 !important;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.5);
}
.datemodalcum-chart_TOP {
  position: absolute;
  top: 0px;
  right: 0PX;
  padding-right: 5px;
  display: flex;
}
.datemodalcum-chart_UNDER_TOP {
  position: absolute;
  top: 0px;
  right: 0PX;
  margin-top: 55px;
  padding-right: 5px;
  display: flex;
}
.datemodalcum-chart {
  position: absolute;
  top: 0px;
  right: 0PX;
  margin-top: 45px;
  padding-right: 5px;
  display: flex;
}
.datemodalcum-chart-OUR {
  position: absolute;
  top: -45px;
  right: 0PX;
  padding-top: 45px;
  padding-right: 5px;
  display: flex;
}
.datemodalcum-chart_fourOUR {
  position: relative;
  top: 0px;
  right: 0PX;
  padding-top: 45px;
  padding-right: 5px;
  display: flex;
}
.div-dmf {
  padding: 10px 0px;
  position: relative;
  width: 100%;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  background-color: #fff;
}
.datemodalcum-chart_fourOURIOCH {
  position: relative;
  top: 0px;
  right: 0PX;
  padding-top: 1px;
  padding-right: 5px;
  display: flex;
}
.div-dmf-IOCH {
  padding: 10px 0px;
  position: relative;
  width: 100%;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  background-color: #fff;
}
.txt-input-fecha-filter-fourOURIOCH {
  font-size: small;
  position: absolute;
  top: 2px;
  left: 15px;
  color: #8b8b8b;
  background-color: #fff;
  padding: 0px 5px;
}
.input-fecha-filter {
  border: 1px solid #CACACA;
  border-radius: 4px;
  padding: 5px 10px;
  /* width: 100%; */
  font-family: monospace;
  margin: 3px 10px;
  -webkit-appearance: listbox;
}
.txt-input-fecha-filter {
  font-size: small;
  position: absolute;
  top: 2px;
  left: 15px;
  color: #8b8b8b;
  background-color: #fff;
  padding: 0px 5px;
}
.txt-input-fecha-filter-fourOUR {
  font-size: small;
  position: absolute;
  top: 2px;
  left: 70px;
  color: #8b8b8b;
  background-color: #fff;
  padding: 0px 5px;
}
.restablecer {
  cursor: pointer;
  margin-top: 3px;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
}

.alert-amon-cir-disable {
  border: 3px solid #2E7D32;
  border-radius: 50%;
  padding: 0px 3px 7px 3px;
}

.bb-card-alert-amon {
  border: 2px solid gray;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  align-items: center;
  align-self: center;
  text-align: center;
}

.alert-amon-cir-active {
  border: 3px solid #D32F2F;
  border-radius: 50%;
  padding: 0px 3px 7px 3px;
  animation: pulse 2s linear infinite;
  /* box-shadow: 0px 0px 10px rgb(255, 0, 0); */
}
@keyframes pulse {
  0% {box-shadow: 0px 0px 10px 5px rgb(255, 0, 0);}
  25% {box-shadow: 0px 0px 5px rgb(255, 0, 0);}
  50% {box-shadow: 0px 0px 0px rgb(255, 0, 0);background-color: #fc5959;}
  75% {box-shadow: 0px 0px 5px rgb(255, 0, 0);}
  100% {box-shadow: 0px 0px 10px rgb(255, 0, 0);}
}

.modal-chart-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
  z-index: +999999;
}
.modal-chart-bar-off {
  display: none;
}
.modal-electric {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(87, 87, 87, 0.7);
  align-items: center;
  align-self: center;
  /* align-content: center; */
  z-index: +99;
}
.modalslectricshini {
  border-radius: 8px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
}

.cubi-data-card {
  border: 1px solid gray;
  border-radius: 5px;
  margin: auto;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  background-color: #D00404;
  color: white;
}
.cubi-data-card:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.cubi-data-card-value {
  background-color: #f8f8f8;
  color: rgb(191, 2, 2);
  border-radius: 5px;
}
.modal-cubidata {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  /* align-items: center; */
  /* align-self: center; */
  /* align-content: center; */
  z-index: +99;
}

.success-led {
  color: #039a08;
  filter: drop-shadow(2px 2px 1px rgba(62, 255, 52, 0.5));
}

.disabled-led {
  color: #fb0000;
  filter: drop-shadow(2px 2px 1px rgba(255, 85, 85, 0.5));
}