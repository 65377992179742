.stilo {
  background-color: #fff;
}

.btn-activo{
  position: absolute;
  z-index: 1000;
  right: 15px;
  /* background-color: red; */
  color: #b1afaf;
  padding: 0px;
  transition: transform .5s ease-in-out;
}
.btn-activo-temp{
  position: absolute;
  z-index: 1000;
  right: 15px;
  bottom: 350px;
  /* background-color: red; */
  color: #b1afaf;
  padding: 0px;
  transition: transform .5s ease-in-out;
}

.btn-activo-2{
  float: right !important;
  position: absolute;
  z-index: 1000;
  left: 15px;
  scale: 1.5;
  /* background-color: red; */
  padding: 0px 3px 3px 3px;
  margin: 0;
  transition: transform .5s ease-in-out;
}

.tittle-litle{
  z-index: 1000;
  left: 40%;
  /* background-color: red; */
  color: #b1afaf;
  text-align: center;
  padding: 0px;
  transition: transform .5s ease-in-out;
}

.minititle{
  position: absolute;
  z-index: 1000;
  left: 50;
  /* background-color: red; */
  padding: 0px;
  transition: transform .5s ease-in-out;
}

/* rect {
  fill: transparent;
} */

.btn-activo-2:hover  {
  transform: rotate(180deg);
  filter: drop-shadow(2px 2px 2px rgb(134, 134, 134));
}
.btn-activo:hover {
  transform: scale(1.5);
  filter: drop-shadow(2px 2px 2px rgb(134, 134, 134));
}


.menu-off {
  display: none;
}

.settings {
  position: fixed;
  background-color: rgba(7, 0, 0, 0.626);
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow: scroll;
}

.card-chart {
  height: 240px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.46);
}

.card-chart-line {
  /* height: 240px; */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.46);
}

.asdfgh {
  background-color: #d8d8d885;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu_grid {
  transition: scale(1) 3s, color 2s;
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000007e;
  z-index: 1001;
}

.row-grid {
  background-color: #fff;
  border-radius: 15px;
  padding: 15px 0px;
  height: 90%; /* borrar */
}
.Mui-selected {
  background-color: rgb(210, 208, 208) !important;
}

.grid-container {
  height: 65%;
  /* background-color: #ff000062; */
  /* scroll-behavior: auto; */
  /* overflow-y: scroll; */
}
.grid-btn-footer{
  width: 100%;
  text-align: right;
  padding-right: 20px;
}

.chart-cube {
  border: 1px solid black;
  resize: both;
  overflow: auto;
}

.select-mum {
  width: '100%';
  padding: 0px;
  margin: 0px;
}
.select-mum__input-container{
  padding: 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.select-mum__value-container {
  padding: 0px !important;
  margin: 0px 0px 0px 5px !important;
}
.select-mum__input {
  padding: 0px !important;
  margin-left: 8px !important;
}
.select-mum__menu {
  background-color: #fff !important;
  z-index: 999999999999999999999999999999999999 !important;
}
.fotmar-inp-date {
  border: 1px solid rgb(191, 191, 191);
  padding: 5px 6px;
  margin: 20px  1px;
  border-radius: 5px;
  width: 100%;
}
.fotmar-inp-date-label {
  position: absolute;
  top: 8px;
  margin-left: 5px;
  background-color: #fff;
  padding: 0px 5px;
}
.fotmar-inp-date-label-s {
  position: absolute;
  top: -14px;
  margin-left: 5px;
  background-color: #fff;
  padding: 0px 5px;
  z-index: 90;
}

.card-ubicacion {
  background-color: #f2f2f2;
  border-radius: 10px;
  border: solid 1px #CACACA;
  padding: 10px 10px 10px 10px;
}
.medida-float-hidrico {
  color: rgb(0, 142, 147);
  position: relative;
  bottom:35px;
  /* background-color: #2722b2; */
  border-radius: 5px;
  padding: 5px;
}
.medida-float-fijo {
  color: rgb(255, 255, 255);
  position: relative;
  bottom:30px;
  background-color: #2722b2;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}
.closessggmonit {
  position: fixed;
  cursor: pointer;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7.5px 14px !important;
}
.input-fecha {
  border: 1px solid #CACACA;
  border-radius: 4px;
  padding: 5px 10px;
  width: 100%;
  font-family: monospace;
}
.show___ {
  display: block;
}
.hidden___ {
  display: none;
}

/* efecto pulso */
.imgMonitorFijoNologin {
  /* position: absolute; */
  height: 700px;
  animation: latido linear 2s infinite;
  filter: drop-shadow(10px 10px 10px rgb(134, 134, 134));
}

.imgMonitorFijoNologinriles {
  /* position: absolute; */
  height: 80vh;
  animation: latido linear 2s infinite;
  filter: drop-shadow(10px 10px 10px rgb(134, 134, 134));
}

@keyframes latido {
  0% { filter: drop-shadow(0px 0px 5px rgb(134, 134, 134));  }
  50% { filter: drop-shadow(0px 0px 20px rgb(96, 96, 96));height: 70vh; }
  100% { filter: drop-shadow(0px 0px 5px rgb(134, 134, 134)); }
}
/* fin efecto pulso */
.inp-edit-table {
  width: 100%;
  height: 100%;
  border-color: transparent;
  text-align: center;
  font-size: 25px;
}
.indicador-arrow {
  position: absolute;
}

.table td, .table th {
  padding: 2px;
}
.table th {
  height: 65px !important;
}
.table-data-positive {
  /* {verticalAlign: 'middle', fontSize: 26, padding:0, margin:0, color:'red'} */
  vertical-align: middle !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: rgb(250, 19, 19) !important;
  color: white !important;
}
.table-data-negative {
  /* {verticalAlign: 'middle', fontSize: 26, padding:0, margin:0, color:'red'} */
  vertical-align: middle !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: green !important;
  color: white !important;
}
