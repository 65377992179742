.modal-ejemplo-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.bg-card-modal-edit-t {
    color: rgb(2, 48, 87);
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid rgb(157, 157, 157);
    box-shadow: 3px 3px 3px rgba(128, 128, 128, 0.556);
}

.bg-card-modal-edit-f {
    color: rgb(2, 48, 87);
    background-color: #e7e6e6;
    border-radius: 8px;
    border: 1px solid rgb(157, 157, 157);
    box-shadow: inset 3px 3px 3px rgba(128, 128, 128, 0.556), inset -3px -3px 3px rgb(255, 255, 255);
}